<template>
  <section class="manage-account-profile view-container">
    <section class="view-header">
      <h1 class="view-title">Manage Account</h1>
      <div class="view-sublinks" v-if="showTabs">
        <LinkWithIcon
          icon="profile"
          text="Profile"
          link="/manage-account/profile"
          className="profile-icon"
          v-bind:active="true"
        />
        <LinkWithIcon
          icon="users"
          text="User Management"
          link="/manage-account/user-management"
          className="users-icon"
        />
        <LinkWithIcon
          icon="lock"
          text="Credential Management"
          link="/manage-account/credential-management"
          className="lock-icon"
        />
        <LinkWithIcon
          icon="lock"
          text="Container Management"
          link="/manage-account/container-management"
          className="lock-icon"
        />
      </div>
    </section>
    <div class="workarea">
      <ProfileWidget />
    </div>
  </section>
</template>

<script>
import LinkWithIcon from "@/components/Shared/LinkWithIcon.vue";
import ProfileWidget from "@/components/ManageAccount/ProfileWidget.vue";

export default {
  name: "ManageAccountProfile",
  components: { LinkWithIcon, ProfileWidget },
  data() {
    return {
      NODE_ENV: process.env.NODE_ENV,
      showTabs: false,
    };
  },
  watch: {
    "$store.state.organizationOptions": function () {
      if (
        this.$store.state.organizationOptions &&
        this.$store.state.organizationOptions.length > 0
      ) {
        this.getData();
      }
    },
  },
  methods: {
    getData() {
      this.$store.state.organizationOptions.map((org) => {
        if (org.role.toLowerCase() === "admin" && this.showTabs === false) {
          this.showTabs = true;
        }
      });
    },
  },
  mounted() {
    if (
      this.$store.state.organizationOptions &&
      this.$store.state.organizationOptions.length > 0
    ) {
      this.getData();
    }
  },
};
</script>

<style lang="scss">
@import "@/styles/_helpers.scss";

.manage-account-profile {
  h1 {
    margin: 0;
  }
  .global-options {
    align-items: flex-start;
  }

  .invite-users-overlay {
    h2 {
      text-align: left;
    }
  }
}
</style>
