<template>
  <section class="manage-account-users view-container">
    <section class="view-header">
      <h1 class="view-title">Manage Account</h1>
      <div class="view-sublinks">
        <LinkWithIcon
          icon="profile"
          text="Profile"
          link="/manage-account/profile"
          className="profile-icon"
        />
        <LinkWithIcon
          icon="users"
          text="User Management"
          link="/manage-account/user-management"
          v-bind:active="true"
          className="users-icon"
        />
        <LinkWithIcon
          icon="lock"
          text="Credential Management"
          link="/manage-account/credential-management"
          className="lock-icon"
        />
        <LinkWithIcon
          icon="lock"
          text="Container Management"
          link="/manage-account/container-management"
          className="lock-icon"
        />
      </div>
    </section>

    <Overlay v-if="isAddUserOverlayOpen" :closeClick="closeUserOveraly">
      <div class="widget invite-users-overlay">
        <div @click="closeUserOveraly()">
          <DisplaySvg name="x" class="close-icon" />
        </div>
        <InviteUsersForm
          :closeClick="closeUserOveraly"
          :email="isAddUserOverlayOpen.email"
          :org="isAddUserOverlayOpen.org"
          :role="isAddUserOverlayOpen.role"
          :userid="isAddUserOverlayOpen.userid"
          :userTable="tableData"
        />
      </div>
    </Overlay>

    <Overlay v-if="isRemoveUserOverlayOpen" :closeClick="closeUserOveraly">
      <div class="widget invite-users-overlay">
        <div @click="closeUserOveraly()">
          <DisplaySvg name="x" class="close-icon" />
        </div>
        <RemoveUserForm
          :closeClick="closeUserOveraly"
          :email="isRemoveUserOverlayOpen.email"
          :org="isRemoveUserOverlayOpen.org"
          :role="isRemoveUserOverlayOpen.role"
          :userid="isRemoveUserOverlayOpen.userid"
        />
      </div>
    </Overlay>

    <div class="workarea">
      <div class="widget full">
        <div class="table-wrapper">
          <div class="table-header user-table-header">
            <div class="manage-account-users-title">
              <h2>User Management</h2>
              <p>
                Invite and edit user access to the selected organization. Admins
                of parent organizations will always have access to any child
                organizations. Viewers will only have access to the currently
                selected organization.
              </p>
            </div>
            <div
              class="user-title-filters"
              v-if="$store.state.organizationOptions"
            >
              <UpdateOrgForm />
            </div>
          </div>
          <div class="table-header user-table-sub-header">
            <div class="manage-account-users-subtitle">
              <h3 v-html="hierarchyString"></h3>
            </div>
            <div class="filters" v-if="hasAdminAccessForCurrentOrg && !loading">
              <NInput
                v-model:value="searchQuery"
                placeholder="Search"
                v-if="tableData.length > 0 && !loading"
              >
                <template #suffix>
                  <DisplaySvg name="search" class="search-icon" />
                </template>
              </NInput>
              <div class="select-inset-label manage-users-select-inset-label">
                <label>Per Page</label>
                <NSelect
                  v-model:value="perPageValue"
                  :options="[
                    { label: 10, value: 10 },
                    { label: 25, value: 25 },
                    { label: 50, value: 50 },
                    { label: 100, value: 100 },
                  ]"
                ></NSelect>
              </div>
              <button class="button blue-btn" :onClick="addUser">
                Add User <DisplaySvg name="plus" class="plus-icon" />
              </button>
            </div>
          </div>
          <NDataTable
            v-if="
              tableData.length > 0 &&
              hasAdminAccessForCurrentOrg &&
              !error &&
              !loading
            "
            :columns="tableColumns"
            :data="filteredData"
            :bordered="false"
            :pagination="pagination"
            class="full manage-account-users-table"
          ></NDataTable>
          <div
            class="site-error"
            v-if="!hasAdminAccessForCurrentOrg && !loading"
          >
            You do not have admin access to the
            {{ $store.state.organization }} organization.
          </div>
          <div
            class="site-message"
            v-if="
              tableData.length == 0 && hasAdminAccessForCurrentOrg && !loading
            "
          >
            The {{ $store.state.organization }} organization currently has no
            users at the base level.
          </div>
          <div class="site-error" v-if="error && !loading">{{ error }}</div>
          <div class="site-loading" v-if="!error && loading">
            <LoadingSpinner /> Loading...
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import router from "@/router";
import CredentialService from "@/services/CredentialService";
import LinkWithIcon from "@/components/Shared/LinkWithIcon.vue";
import EllipsisDropdown from "@/components/Shared/EllipsisDropdown.vue";
import DisplaySvg from "@/components/Shared/DisplaySvg.vue";
import Overlay from "@/components/Shared/Overlay.vue";
import InviteUsersForm from "@/components/ManageAccount/InviteUsersForm.vue";
import RemoveUserForm from "@/components/ManageAccount/RemoveUserForm.vue";
import UpdateOrgForm from "@/components/ManageAccount/UpdateOrgForm.vue";
import LoadingSpinner from "@/components/Shared/LoadingSpinner.vue";
import { getUserManagementErrorMessage } from "@/ErrorMessaging";
import { getNestedOrgs } from "@/Helpers.js";
import { NDataTable, NInput, NSelect } from "naive-ui";
import { h } from "vue";

export default {
  name: "ManageAccountUsers",
  components: {
    LinkWithIcon,
    NDataTable,
    NInput,
    NSelect,
    DisplaySvg,
    Overlay,
    InviteUsersForm,
    RemoveUserForm,
    UpdateOrgForm,
    LoadingSpinner,
  },
  computed: {
    filteredData() {
      return this.tableData.filter((item) =>
        Object.values(item).some((value) =>
          value
            .toString()
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase())
        )
      );
    },
    pagination() {
      return {
        pageSize: this.perPageValue,
        disabled: this.filteredData.length <= this.perPageValue ? true : false,
      };
    },
  },
  data() {
    return {
      error: false,
      loading: true,
      getDataActive: false,
      isAddUserOverlayOpen: false,
      isRemoveUserOverlayOpen: false,
      searchQuery: "",
      tableColumns: [],
      tableData: [],
      NODE_ENV: process.env.NODE_ENV,
      hasAdminAccessForCurrentOrg: false,
      hierarchyString: "",
      perPageValue: 10,
    };
  },
  watch: {
    "$store.state.orgHierarchy": function () {
      if (
        (this.$store.state.organizationOptions &&
          this.$store.state.organizationOptions.length > 0 &&
          this.$store.state.organization &&
          this.$store.state.userData &&
          this.$store.state.orgHierarchy &&
          this.$store.state.orgHierarchy.length > 0) ||
        process.env.NODE_ENV === "demo"
      ) {
        this.getData();
      }
    },
    "$store.state.organizationOptions": function () {
      if (
        (this.$store.state.organizationOptions &&
          this.$store.state.organizationOptions.length > 0 &&
          this.$store.state.organization &&
          this.$store.state.userData &&
          this.$store.state.orgHierarchy &&
          this.$store.state.orgHierarchy.length > 0) ||
        process.env.NODE_ENV === "demo"
      ) {
        this.getData();
      }
    },
    "$store.state.userData": function () {
      if (
        (this.$store.state.organizationOptions &&
          this.$store.state.organizationOptions.length > 0 &&
          this.$store.state.organization &&
          this.$store.state.userData &&
          this.$store.state.orgHierarchy &&
          this.$store.state.orgHierarchy.length > 0) ||
        process.env.NODE_ENV === "demo"
      ) {
        this.getData();
      }
    },
    "$store.state.organization": function () {
      if (
        (this.$store.state.organizationOptions &&
          this.$store.state.organizationOptions.length > 0 &&
          this.$store.state.organization &&
          this.$store.state.userData &&
          this.$store.state.orgHierarchy &&
          this.$store.state.orgHierarchy.length > 0) ||
        process.env.NODE_ENV === "demo"
      ) {
        this.getData();
      }
    },
  },
  methods: {
    addUser(user) {
      this.isAddUserOverlayOpen = {
        email: user ? user.email : false,
        org: this.$store.state.organization,
        role: user ? user.role : false,
        userid: user ? user.userid : false,
      };
    },
    removeUser(user) {
      this.isRemoveUserOverlayOpen = {
        email: user ? user.email : false,
        org: this.$store.state.organization,
        role: user ? user.role : false,
        userid: user ? user.userid : false,
      };
    },
    closeUserOveraly() {
      if (this.isAddUserOverlayOpen) {
        this.isAddUserOverlayOpen = false;
      }

      if (this.isRemoveUserOverlayOpen) {
        this.isRemoveUserOverlayOpen = false;
      }

      // refetch the data
      this.getData();
    },
    getData() {
      if (this.getDataActive) {
        // cancel function if it's currently running
        // prevents multiple calls from going out
        return;
      }
      this.getDataActive = true;
      this.loading = true;

      if (process.env.NODE_ENV === "demo") {
        this.hierarchyString = "Root &#x2022; Demo";
        this.hasAdminAccessForCurrentOrg = true;
        this.tableData = [
          {
            email: "example.admin@infotrustllc.com",
            name: "Example User",
            orgid: "demo",
            role: "Admin",
            self: false,
            userid: "1111",
          },
          {
            email: "example.viewer@infotrustllc.com",
            name: "Example User",
            orgid: "demo",
            role: "Viewer",
            self: false,
            userid: "2222",
          },
        ];
        this.setTableColumns();
        this.getDataActive = false;
        this.loading = false;
        return;
      }

      // before proceeding, we wanna make sure this user
      // has access to see the manage users url
      let hasAdminAccess = false;
      this.$store.state.organizationOptions.map((org) => {
        if (org.role.toLowerCase() === "admin" && hasAdminAccess === false) {
          hasAdminAccess = true;
        }
      });

      if (!hasAdminAccess) {
        return router.push("/manage-account/profile");
      }

      const currentOrgIndex = this.$store.state.organizationOptions.findIndex(
        (x) => x.value === this.$store.state.organization
      );
      const currentOrg = this.$store.state.organizationOptions[currentOrgIndex];
      let tableData = [];

      // get the org hierarchy string
      const divider = "  &#x2022; ";
      let nestedObj = getNestedOrgs(
        currentOrg,
        this.$store.state.organizationOptions,
        this.$store.state.orgHierarchy
      );
      let reversedNestedArray = [].concat(nestedObj.nested_array).reverse();
      const hStringValue = reversedNestedArray.join(divider);
      this.hierarchyString = hStringValue;

      // if the current user is not an admin for this org, do not proceed
      if (currentOrg.role !== "Admin") {
        this.getDataActive = false;
        this.loading = false;
        return (this.hasAdminAccessForCurrentOrg = false);
      } else {
        this.hasAdminAccessForCurrentOrg = true;
      }

      CredentialService.call("userroles.getUsersWithOrgAccess", {
        orgid: currentOrg.value,
        includeUserDetails: true,
      })
        .then((roleResponse) => {
          if (roleResponse.error) {
            this.triggerError({
              message:
                "userroles.getUsersWithOrgAccess - error obj returned from api",
              error: roleResponse.error,
            });
            return Promise.resolve();
          }
          const orgUsers = roleResponse.result.orgUsers;
          const userId = this.$store.state.userData.userid;
          orgUsers.map((userObj) => {
            const roleValue = userObj.roles.includes("admin")
              ? "Admin"
              : "Viewer";

            if (userObj.user.usertags.includes("login-user")) {
              tableData.push({
                orgid: currentOrg.value,
                userid: userObj.user.userid,
                role: roleValue,
                name: userObj.user.name,
                email: userObj.user.email,
                self: userId === userObj.user.userid,
              });
            }
          });

          console.log(orgUsers);

          this.tableData = tableData;
          this.getDataActive = false;
          this.loading = false;
        })
        .catch((error) => {
          this.triggerError({
            message: "userroles.getUsersWithOrgAccess - catch",
            error: error,
          });
        });

      this.setTableColumns();
    },
    setTableColumns() {
      const editUserOnClick = (user) => {
        this.addUser(user);
      };

      const removeUserOnClick = (user) => {
        this.removeUser(user);
      };

      const current_org = this.$store.state.organization;
      this.tableColumns = [
        {
          title: "User",
          render(row) {
            return h(
              "div",
              {
                class: "user-name-section",
              },
              [
                h("p", { class: "name" }, `${row.name}`),
                h("p", { class: "email" }, `${row.email}`),
              ]
            );
          },
        },
        {
          title: "Organization",
          minWidth: 200,
          render() {
            return current_org.toUpperCase();
          },
        },
        {
          title: "Role",
          render(row) {
            return h(
              "div",
              {
                class:
                  row.role.toLowerCase() === "admin"
                    ? "user-admin"
                    : "user-viewer",
              },
              `${row.role}`
            );
          },
        },
        {
          title: "",
          key: "action",
          minWidth: 100,
          render(row) {
            const editOnClick = () => {
              editUserOnClick(row);
            };
            const removeOnClick = () => {
              removeUserOnClick(row);
            };
            if (!row.self) {
              return h(
                EllipsisDropdown,
                {
                  options: [
                    {
                      key: `${row.name
                        .toLowerCase()
                        .split(" ")
                        .join("-")}-edit`,
                      onClick: editOnClick,
                      text: "Edit",
                    },
                    {
                      key: `${row.name
                        .toLowerCase()
                        .split(" ")
                        .join("-")}-remove`,
                      onClick: removeOnClick,
                      text: "Remove",
                      class: "red",
                    },
                  ],
                },
                []
              );
            }
            return "";
          },
        },
      ];
    },
    triggerError(obj) {
      console.log("%cError", "color: white; background-color: red", obj);
      this.error = getUserManagementErrorMessage(
        this.$store.state.organization
      );
      this.loading = false;
      this.getDataActive = false;
    },
  },
  mounted() {
    if (
      (this.$store.state.organizationOptions &&
        this.$store.state.organizationOptions.length > 0 &&
        this.$store.state.organization &&
        this.$store.state.userData &&
        this.$store.state.orgHierarchy &&
        this.$store.state.orgHierarchy.length > 0) ||
      process.env.NODE_ENV === "demo"
    ) {
      this.getData();
    }
  },
};
</script>

<style lang="scss">
@import "@/styles/_helpers.scss";

.manage-account-users {
  h1 {
    margin: 0;
  }

  .manage-users-select-inset-label {
    width: 90px;
    margin: -22px 0 0 0;
    .n-base-selection .n-base-selection-tags,
    .n-select .n-base-selection .n-base-selection-label,
    .n-input {
      padding: 10px 10px 8px 10px;
      height: 38px;
    }
  }
  .filters {
    width: 60% !important;
    @include media($large-lowest, down) {
      width: 100% !important;
      justify-content: flex-start !important;
    }
  }

  .user-title-filters {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-width: 300px;
    gap: 1rem;
    @include media($large-lowest, down) {
      width: 100%;
      justify-content: flex-start;
    }
  }

  .manage-account-users-subtitle {
    width: 40%;
    h3 {
      text-align: left;
      font-size: 14px;
      line-height: 20px;
      text-transform: uppercase;
      color: $dark-gray;
      font-weight: 500;
    }
    @include media($large-lowest, down) {
      width: 100%;
    }
  }

  .manage-account-users-title {
    width: 50%;

    h2 {
      color: $navy;
      text-align: left;
      font-size: 24px;
      margin-top: 0px;
      margin-bottom: 1rem;
      font-weight: 500;
    }

    @include media($large-lowest, down) {
      width: 100%;
    }
  }

  .workarea {
    .widget {
      display: block;
    }
  }
  .table-header {
    justify-content: space-between;
  }

  .table-wrapper {
    min-height: 650px;
    min-width: 600px;
  }
  .user-table-header {
    border-bottom: 1px solid $dark-gray;
    padding-bottom: 50px;
    margin-bottom: 50px;

    .select-inset-label {
      max-width: 250px;
    }
  }
  .user-table-sub-header {
    justify-content: space-between;
  }
  .close-icon {
    height: 16px;
    width: 16px;
    color: $dark-gray;
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .search-icon {
    height: 16px;
    width: 16px;
    position: relative;
    top: -5px;
    color: $dark-gray;
  }
  .blue-btn {
    background-color: $blue;
    color: $white;
    height: 38px;
    padding: 10px 20px;
    flex-shrink: 0;
    @include hover {
      color: $white;
      background-color: $navy !important;
    }
  }
  .plus-icon {
    color: $white;
    height: 14px;
    width: 14px;
    margin-left: 10px;
  }
  .invite-users-overlay {
    h2 {
      text-align: left;
    }
    max-height: calc(100vh - 40px);
    overflow: auto;
    width: calc(100vw - 40px);
    max-width: 550px;
    padding-top: 40px;
  }
}
</style>
