import { createStore } from "vuex";
// import createPersistedState from "vuex-persistedstate"
import Cookies from "js-cookie";
import router from "@/router";
import AdtechService from "@/services/AdtechService";
import MetadataService from "@/services/MetadataService";
import CredentialService from "@/services/CredentialService";

// fixtures
import ScansFixture from "@/fixtures/scans.json";
import ContainersFixture from "@/fixtures/containers.json";
import ConsentRegionFixture from "@/fixtures/consentRegion.json";

// releases
import Release_1_0_0 from "@/fixtures/_releases/release_1_0_0.json";
import Release_1_1_0 from "@/fixtures/_releases/release_1_1_0.json";
import Release_1_1_1 from "@/fixtures/_releases/release_1_1_1.json";
import Release_1_2_0 from "@/fixtures/_releases/release_1_2_0.json";
import Release_1_3_0 from "@/fixtures/_releases/release_1_3_0.json";

import { getDashboardErrorMessage } from "@/ErrorMessaging";

// const persistedState = createPersistedState({
//   paths: ['userData']
// })

async function ipfPaginatedList(ipfService, method, params, resultDataKey) {
	let results = [];
	let currentPageToken = 0;
	while (currentPageToken !== null) {
		let currentParams = { ...params };
		if (currentPageToken) currentParams.pageToken = currentPageToken;
		let response = await ipfService.call(method, currentParams || {});
		if (response?.result[resultDataKey])
			results.push(...response.result[resultDataKey]);
		if (response?.result?.pageToken) {
			currentPageToken = response.result.pageToken;
		} else {
			currentPageToken = null;
		}
	}
	return results;
}

export default createStore({
	//plugins: [persistedState],
	state: {
		userData: null,
		users: null,
		scans: null,
		containers: null,
		consentTimePeriod: null,
		consentTimePeriodOptions: null,
		organization: null,
		organizationOptions: null,
		githubReleaseData: null,
		platformGovernanceFilterOptions: null,
		platformGovernanceFilters: {},
		platformGovernanceLoading: false,
	},
	getters: {
		isAdmin(state) {
			if (state.userData == null) return false;
			if (
				typeof state.userData.user.groups != "undefined" &&
				state.userData.user.groups.includes("admin")
			) {
				return true;
			} else {
				return false;
			}
		},
		currentFilterParams: (state) => {
			const filters = state.platformGovernanceFilters;
			const filterParams = {};

			Object.entries(filters).forEach(([dimension, value]) => {
				// Only add to params if value is not 'all' and not null/undefined
				if (value && value !== "all") {
					// Capitalize the first letter of the dimension
					const capitalizedDimension =
						dimension.charAt(0).toUpperCase() + dimension.slice(1);
					// Use the capitalized dimension in the filter key
					filterParams[`entity.dimensions.${capitalizedDimension}`] = value;
				}
			});

			return filterParams;
		},
		hasActiveFilters: (state) => {
			return Object.values(state.platformGovernanceFilters).some(
				(value) => value !== "all"
			);
		},
		isUserLoggedIn(state) {
			if (state.userData == null) return false;
			return true;
		},
		getContainerMeta: (state) => (containerId, propertyName) => {
			const container =
				state.containers && !state.containers.error
					? state.containers.find((c) => c.containerid === containerId)
					: null;
			return container ? container[propertyName] : null;
		},
		platformGovernanceFiltersCount: (state) => {
			let count = 0;
			const filters = state.platformGovernanceFilters;

			Object.values(filters).forEach((value) => {
				if (value && value !== "all") {
					count++;
				}
			});

			return count;
		},
		getFilterOptionsByDimension: (state) => (dimension) => {
			if (!state.platformGovernanceFilterOptions) return [];
			const option = state.platformGovernanceFilterOptions.find(
				(opt) => opt.dimension?.toLowerCase() === dimension?.toLowerCase()
			);
			return option?.values || [];
		},
	},
	mutations: {
		setUserData(state, userData) {
			Cookies.set("userData", JSON.stringify(userData), { expires: 1 });
			state.userData = userData;
		},
		setFilteredData(state, { rules, aggregate }) {
			// Optional: Store filtered data in the state if needed
			state.filteredRuleRuns = rules;
			state.filteredRuleRunsAggregate = aggregate;
		},
		setPlatformGovernanceLoading(state, loading) {
			state.platformGovernanceLoading = loading;
		},
		logout(state) {
			Cookies.remove("userData");
			state.userData = null;
			router.push("/");
		},
		setUsers(state, users) {
			state.users = users;
		},
		setScans(state, scans) {
			state.scans = scans;
		},
		setContainers(state, containers) {
			state.containers = containers;
			let foundConsentMonitoringContainer =
				containers && !containers.error
					? containers.find((elem) => !!elem.extservices?.consentMonitoring)
					: null;

			let consentMonitoringContainer = foundConsentMonitoringContainer;
			if (
				!foundConsentMonitoringContainer &&
				!containers.error &&
				containers.length > 0
			) {
				consentMonitoringContainer = containers[0];
			}

			state.consentMonitoringContainer = consentMonitoringContainer;
		},
		setConsentTimePeriodOptions(state, timePeriodOptions) {
			state.consentTimePeriodOptions = timePeriodOptions;
		},
		setConsentTimePeriod(state, timePeriod) {
			state.consentTimePeriod = timePeriod;
		},
		setConsentRegionOptions(state, regionOptions) {
			state.consentRegionOptions = regionOptions;
		},
		setConsentRegion(state, region) {
			state.consentRegion = region;
		},
		setOrganizationOptions(state, organizationOptions) {
			state.organizationOptions = organizationOptions;
		},
		setOrganization(state, org) {
			state.organization = org;
		},
		setOrgHierarchy(state, orgHierarchy) {
			state.orgHierarchy = orgHierarchy;
		},
		setGithubReleaseData(state, githubReleaseData) {
			state.githubReleaseData = githubReleaseData;
		},
		setWhatsNewCount(state, whatsNewCount) {
			state.whatsNewCount = whatsNewCount;
		},
		setFeaturePermissions(state, featurePermissions) {
			state.featurePermissions = featurePermissions;
		},
		setPlatformGovernanceFilterOptions(state, filterOptions) {

			// Transform the options structure
			const transformedOptions = filterOptions.map((option) => ({
				dimension: option.dimension,
				values: option.values,
			}));

			state.platformGovernanceFilterOptions = transformedOptions;

			// Initialize filters object if it's empty
			if (Object.keys(state.platformGovernanceFilters).length === 0) {
				const initialFilters = {};
				transformedOptions.forEach(({ dimension }) => {
					initialFilters[dimension.toLowerCase()] = "all";
				});
				state.platformGovernanceFilters = initialFilters;
			}
		},

		updatePlatformGovernanceFilters(state, filters) {
			state.platformGovernanceFilters = {
				...state.platformGovernanceFilters,
				...filters,
			};
			localStorage.setItem(
				"PLATFORM_GOVERNANCE_FILTERS",
				JSON.stringify(state.platformGovernanceFilters)
			);
		},

		resetPlatformGovernanceFilters(state) {
			const resetFilters = {};
			if (state.platformGovernanceFilterOptions) {
				state.platformGovernanceFilterOptions.forEach(({ dimension }) => {
					resetFilters[dimension.toLowerCase()] = "all";
				});
			}
			state.platformGovernanceFilters = resetFilters;
			localStorage.setItem(
				"PLATFORM_GOVERNANCE_FILTERS",
				JSON.stringify(resetFilters)
			);
		},

		initializePlatformGovernanceFilters(state) {
			const savedFilters = localStorage.getItem("PLATFORM_GOVERNANCE_FILTERS");
			if (savedFilters) {
				state.platformGovernanceFilters = JSON.parse(savedFilters);
			}
		},
	},
	actions: {
		getScans({ commit, state }) {
			if (state.organization && process.env.NODE_ENV !== "demo") {
				AdtechService.call("scans.list", {
					orgid: state.organization,
					limit: 100,
					filter: { status: 'FINISHED' }
				})
					.then((response) => {
						if (response.error) {
							console.log("%cError", "color: white; background-color: red", {
								message:
									"store/index.js - getScans - scans.list - error obj returned from api",
								error: response.error,
							});
							commit("setScans", {
								error: getDashboardErrorMessage(state.organization),
							});
							return Promise.resolve();
						}
						const filteredScans = (response.result.scans || [])
							.filter((elem) => elem.status === 'FINISHED')
							.sort((a, b) => (a.start_time > b.start_time) ? -1 : 1)
							.slice(0, 10);

						if (filteredScans.length === 0) {
							commit("setScans", {
								error: getDashboardErrorMessage(state.organization),
							});
						} else {
							commit("setScans", filteredScans);
						}
					})
					.catch((error) => {
						console.log("%cError", "color: white; background-color: red", {
							message: "store/index.js - getScans - scans.list - catch",
							error: error,
						});
						commit("setScans", {
							error: getDashboardErrorMessage(state.organization),
						});
					});
			} else if (process.env.NODE_ENV === "demo") {
				commit("setScans", ScansFixture);
			}
		},
		async initializePlatformGovernanceFilters({ commit, dispatch }) {
			// First, make sure we have scans
			if (!this.state.scans || !this.state.scans.length) {
				await dispatch("getScans");
			}

			commit("initializePlatformGovernanceFilters");
			await dispatch("fetchPlatformGovernanceFilterOptions");
		},

		getContainers({ commit, state }) {
			if (state.organization && process.env.NODE_ENV !== "demo") {
				ipfPaginatedList(
					MetadataService,
					"analyticscontainers.list",
					{ orgid: state.organization, includeChildOrgs: true },
					"containers"
				)
					.then((containers) => {
						if (containers.error) {
							console.log("%cError", "color: white; background-color: red", {
								message:
									"store/index.js - getContainers - analyticscontainers.list - error obj returned from api",
								error: containers.error,
							});
							commit("setContainers", {
								error: getDashboardErrorMessage(state.organization),
							});
							return Promise.resolve();
						}

						commit("setContainers", containers);
					})
					.catch((error) => {
						console.log("%cError", "color: white; background-color: red", {
							message:
								"store/index.js - getContainers - analyticscontainers.list - catch",
							error: error,
						});
						commit("setContainers", {
							error: getDashboardErrorMessage(state.organization),
						});
					});
			} else if (process.env.NODE_ENV === "demo") {
				commit("setContainers", ContainersFixture);
			}
		},
		updatePlatformGovernanceFilters({ commit }, filters) {
			commit("updatePlatformGovernanceFilters", filters);
		},

		resetPlatformGovernanceFilters({ commit }) {
			commit("resetPlatformGovernanceFilters");
		},

		getConsentRegionOptions({ commit, state }) {
			if (
				(!state.containers ||
					state.containers.length === 0 ||
					state.containers.error) &&
				process.env.NODE_ENV !== "demo"
			) {
				return;
			} else if (process.env.NODE_ENV === "demo") {
				return commit("setConsentRegionOptions", ConsentRegionFixture);
			}

			return [state.consentMonitoringContainer].forEach((container) => {
				MetadataService.call("runConsentMonitoringQuery", {
					containerid: container.containerid,
					query: `
          SELECT
            DISTINCT(scan_region) as scanRegion
          FROM __get_scanned_sites___;
          `,
				})
					.then((response) => {
						if (response.error || !response.result) {
							console.log("%cError", "color: white; background-color: red", {
								message:
									"store/index.js - getConsentRegionOptions - runConsentMonitoringQuery - error obj returned from api / or no result obj returned",
								error: response.error,
								missingResultObject: !response.result,
							});
							return Promise.resolve();
						}

						const regions = response.result.queryResult.data;
						let regionOptions = [];
						regions.forEach((region) => {
							regionOptions.push({
								label: region.f[0].v,
								value: region.f[0].v,
							});
						});
						commit("setConsentRegionOptions", regionOptions);
					})
					.catch((error) => {
						console.log("%cError", "color: white; background-color: red", {
							message:
								"store/index.js - getConsentRegionOptions - runConsentMonitoringQuery - catch",
							error: error,
						});
					});
			});
		},
		getOrganizationOptions({ commit, state }) {
			// fake data for demo env
			if (process.env.NODE_ENV === "demo") {
				commit("setOrganizationOptions", [
					{
						label: "demo",
						value: "demo",
						role: "Admin",
					},
					{
						label: "example-brand-1",
						value: "example-brand-1",
						role: "Admin",
					},
					{
						label: "example-brand-2",
						value: "example-brand-2",
						role: "Admin",
					},
				]);
				commit("setOrganization", "demo");
				commit("setOrgHierarchy", [
					{
						name: "example-brand-1",
						orgid: "example-brand-1",
						parent_orgid: "demo",
					},
					{
						name: "example-brand-2",
						orgid: "example-brand-2",
						parent_orgid: "demo",
					},
					{
						name: "demo",
						orgid: "demo",
						parent_orgid: "root",
					},
				]);

				return;
			}

			const userId = state.userData.userid;
			const userPrimaryOrg = state.userData.user.primary_orgid;
			let orgRoles = [];

			// The API call userroles.getOrgsWithUserRoles returns
			// a list of all orgs the user has access to
			CredentialService.call("userroles.getOrgsWithUserRoles", {
				recurse: true,
			}).then((response) => {
				if (response.error) {
					console.log("%cError", "color: white; background-color: red", {
						message:
							"store/index.js - getOrganizationOptions - userroles.getOrgsWithUserRoles - error obj returned from api",
						error: response.error,
					});
					return Promise.resolve();
				}

				// now loop through each org the user has access to
				// and figure out what role level they have (Admin or Viewer)
				const promiseArray = response.result.orgids.map((org) => {
					return CredentialService.call("userroles.getEffective", {
						orgid: org,
						userid: userId,
					})
						.then((orgResponse) => {
							if (orgResponse.error) {
								console.log("%cError", "color: white; background-color: red", {
									message:
										"store/index.js - getOrganizationOptions - userroles.getOrgsWithUserRoles - error obj returned from api",
									error: orgResponse.error,
									info: `call for ${org} org`,
								});
								return Promise.resolve();
							}

							const role =
								orgResponse &&
								orgResponse.result &&
								orgResponse.result.roles &&
								orgResponse.result.roles.includes("admin")
									? "Admin"
									: "Viewer";

							orgRoles.push({
								label: org,
								value: org,
								role: role,
							});
						})
						.catch((error) => {
							console.log("%cError", "color: white; background-color: red", {
								message:
									"store/index.js - getOrganizationOptions - userroles.getOrgsWithUserRoles - catch",
								error: error,
								info: `call for ${org} org`,
							});
						});
				});

				return Promise.all(promiseArray)
					.then(() => {
						commit("setOrganizationOptions", orgRoles);
						const ADTECH_ORG = localStorage.getItem("ADTECH_ORG");
						// the initial org selected should be the user's primary org
						// only if no other org was previously selected
						let currentOrgSelected = ADTECH_ORG ? ADTECH_ORG : userPrimaryOrg;
						commit("setOrganization", currentOrgSelected);
					})
					.catch((error) => {
						console.log("%cError", "color: white; background-color: red", {
							message:
								"store/index.js - getOrganizationOptions - promiseArray - catch",
							error: error,
						});
					});
			});

			// also get the org hierarchy
			CredentialService.call("orgs.list", {
				limit: 1000,
				orgid: "root",
				includeChildOrgs: true,
			}).then((response) => {
				commit("setOrgHierarchy", response.result.orgs);
			});
		},
		getGithubReleaseData({ commit }) {
			const releaseData = [
				{ ...Release_1_3_0 },
				{ ...Release_1_2_0 },
				{ ...Release_1_1_1 },
				{ ...Release_1_1_0 },
				{ ...Release_1_0_0 },
			];

			const ADTECH_RELEASES = localStorage.getItem("ADTECH_RELEASES");
			let localStorageReleases = ADTECH_RELEASES
				? JSON.parse(ADTECH_RELEASES)
				: [];
			let whatsNewCount = 0;
			if (localStorageReleases && localStorageReleases.length > 0) {
				const findIndex = releaseData.findIndex((i) => {
					return i && `${i.id}` === `${localStorageReleases[0]}`;
				});

				if (findIndex === -1) {
					whatsNewCount = releaseData.length;
				} else {
					whatsNewCount = findIndex;
				}
			} else {
				whatsNewCount = releaseData.length;
			}

			commit("setWhatsNewCount", whatsNewCount);
			commit("setGithubReleaseData", releaseData);
		},
		getFeaturePermissions({ commit, state }) {
			let featurePermissions = {
				tagConsent: true,
				platformGovernance: true,
				mediaPerformance: true,
			};

			if (
				!state.organizationOptions ||
				state.organizationOptions.length === 0
			) {
				featurePermissions = {
					tagConsent: false,
					platformGovernance: false,
					mediaPerformance: false,
				};
			}

			commit("setFeaturePermissions", featurePermissions);
		},
		async fetchPlatformGovernanceFilterOptions({ commit, state }) {
			try {
				if (!state.scans?.[0]?.scanid) {
					console.log("No scan ID available, cannot fetch filter options");
					return;
				}

				const response = await AdtechService.call(
					"scanentities.listDimensions",
					{
						orgid: state.organization,
						scanid: state.scans[0].scanid,
					}
				);

				if (response.error) {
					console.error("Error fetching filter dimensions:", response.error);
					return;
				}

				// Transform the response data directly
				const filterOptions = response.result.data.map((item) => ({
					dimension: item.dimension,
					values: item.values,
				}));

				commit("setPlatformGovernanceFilterOptions", filterOptions);
			} catch (error) {
				console.error("Error fetching filter dimensions:", error);
			}
		},
		async fetchFilteredRuleRuns({ state, getters }, { fields = [] } = {}) {
			const filterParams = getters.currentFilterParams;

			if (!state.scans?.[0]?.scanid) {
				return { error: "No scan ID available" };
			}

			try {
				const response = await AdtechService.call(
					"reports.custom.scanRuleRuns",
					{
						scanid: state.scans[0].scanid,
						fields,
						filter: filterParams,
					}
				);

				return response;
			} catch (error) {
				return { error };
			}
		},
		async fetchFilteredRuleRunsAggregate(
			{ state, getters },
			{ groupBy = [] } = {}
		) {
			if (!state.scans?.[0]?.scanid) {
				return { error: "No scan ID available" };
			}

			if (process.env.NODE_ENV === "demo") {
				return import("@/fixtures/ruleRunsAggregate.json");
			}

			try {
				const filterParams = getters.currentFilterParams;

				return await AdtechService.call(
					"reports.custom.scanRuleRunsAggregate",
					{
						scanid: state.scans[0].scanid,
						filter: filterParams,
						groupBy,
					}
				);
			} catch (error) {
				console.error("Error fetching rule runs aggregate:", error);
				return { error };
			}
		},
		async applyFilters({ dispatch, commit }, filters) {
			commit("setPlatformGovernanceLoading", true);

			try {
				await dispatch("updatePlatformGovernanceFilters", filters);

				const [rulesResponse, aggregateResponse] = await Promise.all([
					dispatch("fetchFilteredRuleRuns", {
						fields: ["entity", "violations", "container", "rule"],
					}),
					dispatch("fetchFilteredRuleRunsAggregate", {
						groupBy: ["severity", "containerid"],
					}),
				]);
				return {
					rules: rulesResponse,
					aggregate: aggregateResponse,
				};
			} catch (error) {
				console.error("❌ Error applying filters:", error);
				throw error;
			} finally {
				commit("setPlatformGovernanceLoading", false);
			}
		},
	},
});
