<template>
  <div class="add-cred-form">
    <div class="select-inset-label">
      <label>Container Name</label>
      <n-input v-model:value="name" />
    </div>

    <div class="select-inset-label">
      <label>Credentials</label>
      <n-select v-model:value="credentials" :options="credentialIdOptions" />
    </div>

    <div class="select-inset-label">
      <label>Container Type</label>
      <n-select v-model:value="type" :options="typeOptions" />
    </div>

    <div v-if="serviceFields[type]">
      <div class="select-inset-label" v-for="fieldKey in Object.keys(serviceFields[type])" :key="fieldKey">
        <label>{{ serviceFields[type][fieldKey].label }}</label>
        <n-input 
          v-model:value="serviceFields[type][fieldKey].value" 
          v-if="serviceFields[type][fieldKey].type === 'n-input'" 
        />
        <n-select 
          v-model:value="serviceFields[type][fieldKey].value" 
          v-if="serviceFields[type][fieldKey].type === 'n-select'" 
          :options="serviceFields[type][fieldKey].options" 
        />
        <n-dynamic-tags 
          v-model:value="serviceFields[type][fieldKey].value" 
          v-if="serviceFields[type][fieldKey].type === 'n-dynamic-tags'" 
        />
      </div>
    </div>    

    <div class="site-error" v-if="error && !loading">{{ error }}</div>

    <div class="buttons">
      <button class="button white-btn" @click="this.closeClick" v-if="this.closeClick">
        Close
      </button>
      <button class="button blue-btn" @click="editContainer.container ? update() : add()">
        {{ editContainer.container ? "Update" : "Add" }}
      </button>
    </div>

    <div class="site-loading" v-if="!error && loading">
      <LoadingSpinner /> Loading...
    </div>
  </div>
</template>

<script>
import CredentialService from "@/services/CredentialService";
import LoadingSpinner from "@/components/Shared/LoadingSpinner.vue";
import { NSelect, NInput, NDynamicTags } from "naive-ui";
import MetadataService from "@/services/MetadataService";

export default {
  name: "AddForm",
  components: { NSelect, NInput, NDynamicTags, LoadingSpinner },
  props: [
    "closeClick",
    "editContainer"
  ],
  data() {
    return {
      loading: false,
      error: null,
      name: "",
      type: "",
      credentials: "",
      typeOptions: [
        {
          'label': 'DV360',
          'value': 'dv360'
        },
        {
          'label': 'SA360',
          'value': 'sa360'
        },
        {
          'label': 'CM360',
          'value': 'cm360'
        },
        {
          'label': 'GTM',
          'value': 'gtm'
        },
      ],
      credentialIdOptions: [],
      serviceFields: {
        gtm: {
          accountId: {
            label: "Account ID",
            type: "n-input",
            value: "",
          },
          containerId: {
            label: "Container ID",
            type: "n-input",
            value: "",
          },
          containerName: {
            label: "Container Name",
            type: "n-input",
            value: "",
          }
        },
        sa360: {
          loginCustomerId: {
            label: "Login Customer ID",
            type: "n-input",
            value: ""
          },
          customerId: {
            label: "Customer ID",
            type: "n-input",
            value: ""
          },
          customerName: {
            label: "Customer Name",
            type: "n-input",
            value: ""
          }
        },
        dv360: {
          partnerId: {
            label: "Partner ID",
            type: "n-input",
            value: ""
          },
          advertiserId: {
            label: "Advertiser ID",
            type: "n-input",
            value: ""
          },
          advertiserName: {
            label: "Advertiser Name",
            type: "n-input",
            value: ""
          }
        },
        cm360: {
          accountId: {
            label: "Account ID",
            type: "n-input",
            value: ""
          },
          advertiserId: {
            label: "Advertiser ID",
            type: "n-input",
            value: ""
          },
          advertiserName: {
            label: "Advertiser Name",
            type: "n-input",
            value: ""
          }
        }
      }
    };
  },
  methods: {
    getCredentialIdOptions() {
      CredentialService.call("credentials.list", { orgid: this.$store.state.organization }).then(response => {
        this.credentialIdOptions = response.result.credentials.map(credential => {
          return {
            label: credential.name,
            value: credential.credentialid
          };
        });
      });
    },
    add() {
      let extServices = null;
      extServices = this.generateServiceConfig();
      MetadataService.call('analyticscontainers.create', {
        data: {
          "orgid": this.$store.state.organization,
          "name": this.name,
          "containertype": "site",
          "extservices": extServices
        }
      }).then(response => {
        if (response.error) {
          this.triggerError(response.error);
          return;
        }
        this.closeClick();
      });
    },
    update() {
      let extServices = null;
      extServices = this.generateServiceConfig();

      MetadataService.call('analyticscontainers.update', {
        containerid: this.editContainer.container.containerid,
        updates: {
          name: this.name,
          extservices: extServices
        }
      }).then(response => {
        if (response.error) {
          this.triggerError(response.error);
          return;
        }
        this.closeClick();
      });
    },
    triggerError(obj) {
      console.log("%cError", "color: white; background-color: red", obj);
      this.error = "Oops. Something went wrong.";
      this.loading = false;
    },
    initializeFormIfEdit() {
      if (this.editContainer.container != null) {
        this.name = this.editContainer.container.name;
        this.type = this.editContainer.container.type;

        const extServices = this.editContainer.container.extservices;
        if (extServices && this.type in extServices) {
          const serviceData = extServices[this.type];
          console.log(serviceData);

          if (this.serviceFields[this.type]) {
            Object.keys(this.serviceFields[this.type]).forEach((fieldKey) => {
              if (serviceData[fieldKey] !== undefined) {
                this.serviceFields[this.type][fieldKey].value = serviceData[fieldKey];
              }
            });

            this.credentials = serviceData.credentialspecs?.default?.credentialids?.[0] || "";
          }
        }
      }
    },
    generateServiceConfig() {
      if (!this.type || !this.serviceFields[this.type]) {
        console.error("Invalid or undefined service type.");
        return null;
      }

      const serviceConfig = {
        [this.type]: Object.keys(this.serviceFields[this.type]).reduce(
          (acc, key) => {
            acc[key] = this.serviceFields[this.type][key].value;
            return acc;
          },
          {}
        )
      };

      serviceConfig[this.type].credentialspecs = {
        default: {
          credentialids: [this.credentials]
        }
      };

      return serviceConfig;
    }
  },
  mounted() {
    this.getCredentialIdOptions();
    this.initializeFormIfEdit();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_helpers.scss";

.add-cred-form {
  width: 100%;

  .site-error {
    margin-top: 24px;
    margin-bottom: 4px;
  }

  .name-label {
    color: $dark-gray;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 5px;
    margin-top: 20px;
  }

  .name-value {
    color: $navy;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 10px;

    &.green {
      color: $green;
    }

    &.orange {
      color: $orange;
    }
  }

  .detail-value {
    margin-top: -10px;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;
    color: $dark-gray;
  }

  .info-block {
    position: relative;
    background-color: $light-gray;
    border-radius: $border-radius-2;
    overflow: hidden;
    margin: 30px 0 10px 0;

    p {
      padding: 16px;
      margin: 0;
      width: calc(100% - 50px);
      margin-left: 50px;
      font-size: 15px;
      line-height: 21px;
    }

    .info-wrapper {
      width: 50px;
      height: 100%;
      background-color: $blue;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      left: 0;

      .info-icon {
        width: 20px;
        height: 20px;
        color: $white;
      }
    }
  }

  .title {
    text-align: left;
  }

  .blue-btn {
    background-color: $blue;
    color: $white;
    margin-left: 16px;
  }

  &.widget {
    align-items: flex-start;
  }

  button {
    margin: 20px 0 0 0;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
</style>
