<template>
  <section class="auth-credentials view-container">
    <section class="view-header">
      <h1 class="view-title">Manage Account</h1>
      <div class="view-sublinks">
        <LinkWithIcon icon="profile" text="Profile" link="/manage-account/profile" className="profile-icon" />
        <LinkWithIcon icon="users" text="User Management" link="/manage-account/user-management"
          className="users-icon" />
        <LinkWithIcon icon="lock" text="Credential Management" link="/manage-account/credential-management"
          className="lock-icon" />
        <LinkWithIcon icon="lock" text="Container Management" link="/manage-account/container-management"
          className="lock-icon" v-bind:active="true" />
      </div>
    </section>

    <Overlay v-if="isAddOverlayOpen" :closeClick="closeOverlay">
      <div class="widget add-cred-overlay">
        <div @click="closeOverlay()">
          <DisplaySvg name="x" class="close-icon" />
        </div>
        <AddForm :closeClick="closeOverlay" :orgid="isAddOverlayOpen.orgid" :editContainer="isAddOverlayOpen" />
      </div>
    </Overlay>

    <Overlay v-if="isRemoveOverlayOpen" :closeClick="closeOverlay">
      <div class="widget add-cred-overlay">
        <div @click="closeOverlay">
          <DisplaySvg name="x" class="close-icon" />
        </div>
        <RemoveForm :closeClick="closeOverlay" :containerid="isRemoveOverlayOpen.containerid"
          :orgid="isRemoveOverlayOpen.orgid" />
      </div>
    </Overlay>

    <div class="workarea">
      <div class="widget full auth-credentials">
        <div class="table-wrapper">
          <div class="table-header cred-table-header">
            <div class="manage-account-cred-title">
              <h2>Containers</h2>
              <p>
                Manage your organization's containers for AdTech DNA.
              </p>
            </div>
            <div class="filters" v-if="$store.state.organizationOptions">
              <UpdateOrgForm />
            </div>
          </div>
          <div class="table-header cred-table-sub-header">
            <div class="manage-account-cred-title">
              <h3 v-html="hierarchyString"></h3>
            </div>
            <div class="filters" v-if="!loading">
              <NInput v-model:value="searchQuery" placeholder="Search" v-if="tableData.length > 0 && !loading">
                <template #suffix>
                  <DisplaySvg name="search" class="search-icon" />
                </template>
              </NInput>
              <button class="button blue-btn" :onClick="() => {
                addContainer(false);
              }
                ">
                Add Container
                <DisplaySvg name="plus" class="plus-icon" />
              </button>
            </div>
          </div>
        </div>
        <NDataTable v-if="
          tableData.length > 0 &&
          !error &&
          !loading
        " :columns="tableColumns" :data="filteredData" :bordered="false" :pagination="pagination"
          class="full manage-account-creds--users-table"></NDataTable>
        <div class="site-message" v-if="tableData.length == 0 && !loading">
          The {{ $store.state.organization }} organization currently has no containers at the base level.
        </div>
        <div class="site-error" v-if="error && !loading">{{ error }}</div>
        <div class="site-loading" v-if="!error && loading">
          <LoadingSpinner /> Loading...
        </div>
      </div>
    </div>
  </section>
</template>

<script>
//import router from "@/router";
import MetadataService from "@/services/MetadataService";
import LinkWithIcon from "@/components/Shared/LinkWithIcon.vue";
import EllipsisDropdown from "@/components/Shared/EllipsisDropdown.vue";
import DisplaySvg from "@/components/Shared/DisplaySvg.vue";
import Overlay from "@/components/Shared/Overlay.vue";
import AddForm from "@/components/ManageAccount/Containers/AddForm.vue";
import RemoveForm from "@/components/ManageAccount/Containers/RemoveForm.vue";
import UpdateOrgForm from "@/components/ManageAccount/UpdateOrgForm.vue";
import LoadingSpinner from "@/components/Shared/LoadingSpinner.vue";
import { NDataTable, NInput } from "naive-ui";
import { h } from "vue";

export default {
  name: "ManageAccountContainers",
  components: {
    LinkWithIcon,
    NDataTable,
    NInput,
    DisplaySvg,
    AddForm,
    RemoveForm,
    Overlay,
    UpdateOrgForm,
    LoadingSpinner,
  },
  computed: {
    filteredData() {
      return this.tableData.filter((item) =>
        Object.values(item).some((value) =>
          value !== null && value !== undefined && // Ensure value is not null or undefined
          value.toString().toLowerCase().includes(this.searchQuery.toLowerCase())
        )
      );
    },
    pagination() {
      return {
        pageSize: 10,
        disabled: this.filteredData.length <= 10 ? true : false,
      };
    },
  },
  methods: {
    addContainer() {
      // Opens the overlay in "Add" mode
      this.isAddOverlayOpen = {
        container: null, // Pass `null` to indicate adding a new container
        orgid: this.$store.state.organization,
      };
    },
    editContainer(container) {
      // Opens the overlay in "Edit" mode, passing the existing container data
      this.isAddOverlayOpen = {
        container: container || null, // Ensure a valid container object or `null`
        orgid: this.$store.state.organization,
      };
    },
    removeContainer(container) {
      this.isRemoveOverlayOpen = {
        containerid: container ? container.containerid : false,
        orgid: this.$store.state.organization,
      };
    },
    closeOverlay() {
      this.isAddOverlayOpen = false;
      this.isRemoveOverlayOpen = false;
      this.getData();
    },
    async getData() {
      if (this.getDataActive) return;

      this.getDataActive = true;
      this.loading = true;

      try {
        const fetchAllPages = async (pageToken = null, accumulatedData = []) => {
          const params = {
            orgid: this.$store.state.organization,
            filter: { deleted: false },
          };

          if (pageToken) {
            params.pageToken = pageToken;
          }

          const response = await MetadataService.call("analyticscontainers.list", params);

          if (!response || !response.result || !response.result.containers) {
            return accumulatedData;
          }

          const currentPageData = response.result.containers.map((container) => ({
            ...container,
            type: Object.keys(container.extservices || {})[0],
          }));

          const allData = [...accumulatedData, ...currentPageData];

          if (response.result.hasMore) {
            return fetchAllPages(response.result.pageToken, allData);
          }

          return allData;
        };

        const tableData = await fetchAllPages();
        this.tableData = [...tableData];
        this.setTableColumns();
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        this.getDataActive = false;
        this.loading = false;
      }
    },
    setTableColumns() {
      const editRowOnClick = (row) => {
        this.editContainer(row);
      };
      const removeRowOnClick = (row) => {
        this.removeContainer(row);
      };

      this.tableColumns = [
        {
          title: "Container ID",
          minWidth: 200,
          render(row) {
            return h("p", {}, row.containerid);
          },
        },
        {
          title: "Name",
          minWidth: 200,
          render(row) {
            return h("p", {}, row.name);
          },
        },
        {
          title: "Service",
          minWidth: 120,
          render(row) {
            return h("p", {}, row.type);
          },
        },
        {
          title: "",
          key: "action",
          minWidth: 100,
          render(row) {
            const editOnClick = () => {
              editRowOnClick(row);
            };
            const removeOnClick = () => {
              removeRowOnClick(row);
            };

            const options = [
              {
                key: `${row.containerid}-edit`,
                onClick: editOnClick,
                text: "Edit",
              },
              {
                key: `${row.containerid}-remove`,
                onClick: removeOnClick,
                text: "Remove",
                class: "red",
              },
            ];

            return h(
              EllipsisDropdown,
              {
                options: options,
              },
              {
                // Provide an empty slot function for better performance
                default: () => null,
              }
            );
          },
        },
      ];
    },
  },
  data() {
    return {
      error: false,
      loading: true,
      getDataActive: false, // only used to tell if the current getData call is running
      NODE_ENV: process.env.NODE_ENV,
      isAddOverlayOpen: false,
      isRemoveOverlayOpen: false,
      searchQuery: "",
      tableColumns: [],
      tableData: [],
      hierarchyString: "root",
      rootClientId: null,
    };
  },
  watch: {
    "$store.state.orgHierarchy": function () {
      if (
        (this.$store.state.organizationOptions &&
          this.$store.state.organizationOptions.length > 0 &&
          this.$store.state.organization &&
          this.$store.state.userData &&
          this.$store.state.orgHierarchy &&
          this.$store.state.orgHierarchy.length > 0) ||
        process.env.NODE_ENV === "demo"
      ) {
        this.getData();
      }
    },
    "$store.state.organizationOptions": function () {
      if (
        (this.$store.state.organizationOptions &&
          this.$store.state.organizationOptions.length > 0 &&
          this.$store.state.organization &&
          this.$store.state.userData &&
          this.$store.state.orgHierarchy &&
          this.$store.state.orgHierarchy.length > 0) ||
        process.env.NODE_ENV === "demo"
      ) {
        this.getData();
      }
    },
    "$store.state.userData": function () {
      if (
        (this.$store.state.organizationOptions &&
          this.$store.state.organizationOptions.length > 0 &&
          this.$store.state.organization &&
          this.$store.state.userData &&
          this.$store.state.orgHierarchy &&
          this.$store.state.orgHierarchy.length > 0) ||
        process.env.NODE_ENV === "demo"
      ) {
        this.getData();
      }
    },
    "$store.state.organization": function () {
      if (
        (this.$store.state.organizationOptions &&
          this.$store.state.organizationOptions.length > 0 &&
          this.$store.state.organization &&
          this.$store.state.userData &&
          this.$store.state.orgHierarchy &&
          this.$store.state.orgHierarchy.length > 0) ||
        process.env.NODE_ENV === "demo"
      ) {
        this.getData();
      }
    },
  },
  mounted() {
    if (this.$store.state.organization != null) this.getData();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_helpers.scss";

.auth-credentials {
  display: block;

  .view-title,
  .title {
    margin: 0;
    width: 100%;
    text-align: left;
  }

  .manage-account-cred-title {
    width: 50%;

    h2 {
      color: $navy;
      text-align: left;
      font-size: 24px;
      margin-top: 0px;
      margin-bottom: 1rem;
      font-weight: 500;
    }

    h3 {
      text-align: left;
      font-size: 14px;
      line-height: 20px;
      text-transform: uppercase;
      color: $dark-gray;
      font-weight: 500;
    }

    @include media($large-lowest, down) {
      width: 100%;
    }
  }

  .description {
    width: 100%;
    text-align: left;
    max-width: 600px;
    margin-bottom: 50px;
  }

  .search-icon {
    height: 16px;
    width: 16px;
    position: relative;
    top: -5px;
    color: $dark-gray;
  }

  .blue-btn {
    background-color: $blue;
    color: $white;

    @include hover {
      color: $white;
      background-color: $navy !important;
    }
  }

  .close-icon {
    height: 16px;
    width: 16px;
    color: $dark-gray;
    position: absolute;
    right: 20px;
    top: 20px;
  }

  .plus-icon {
    color: $white;
    height: 14px;
    width: 14px;
    margin-left: 10px;
  }

  .table-header {
    justify-content: flex-end;
  }

  .add-cred-overlay {
    h2 {
      text-align: left;
    }

    width: calc(100vw - 40px);
    max-width: 550px;
    padding-top: 40px;
  }

  .cred-table-header {
    border-bottom: 1px solid $dark-gray;
    padding-bottom: 50px;
    margin-bottom: 50px;

    @include media($large-lowest, down) {
      .filters {
        justify-content: flex-start;
      }
    }

    .select-inset-label {
      max-width: 250px;
    }
  }

  .cred-table-sub-header {
    justify-content: space-between;
  }
}
</style>
